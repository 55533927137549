@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,500,700&display=swap);
/* @import url("https://fonts.googleapis.com/css?family=Inria+Serif:400,400i,700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css?family=Karla:400,400i,700&display=swap"); */

@font-face {
  font-family: "Harmonia600";
  src: url(/static/media/HarmoniaSansProCyr-Bold.ee8663cd.otf) format("opentype");
}

@font-face {
  font-family: "Harmonia500";
  src: url(/static/media/HarmoniaSansProCyr-SemiBd.a10ef598.otf) format("opentype");
}

@font-face {
  font-family: "Harmonia200";
  src: url(/static/media/HarmoniaSansProCyr-Light.9380f954.otf) format("opentype");
}

@font-face {
  font-family: "Harmonia400";
  src: url(/static/media/HarmoniaSansProCyr-Regular.6fd7e0f0.otf) format("opentype");
}

:root {
  /* font-size: 20px; */
  /* --base-color1: #353e49; */
  --base-color1: #16403D;
  --base-color2: #f3f8fa;
  /* --color1: #ff9497;
  --color2: #008da0;
  --color3: #192b5f; */
  /* --color1: #56823C;
  --color2: #843982;
  --color3: #137B89; */

  --color1: #357266;
  --color2: #FF716F;
  --color3: #155275;
}

html,
body {
  margin: 0;
  padding: 0;
  background: var(--base-color2);
  color: var(--base-color1);
  overflow: hidden;
  font-family: "Harmonia500", sans-serif;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;
}

/* .canvas > div {
  z-index: 10;
} */
.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  font-size: 20px;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  /* z-index: 1; */
  /* background: red; */
  /* pointer-events: none; */
  /* display: none; */
  overflow: auto;
}

/* MENU START */
ul {
  list-style-type: none;
}

.space-1 {
  padding-top: 10vh;
}

.space-2 {
  padding-top: 20vh;
}

.ham {
  width: 27px;
  overflow: visible;
}

#svg-pattern {
  background: url('/img/pattern-min.png');
  background-size: cover;
  opacity: 0.05
}

.ham {
  display: none;
}

.ham .st0:nth-child(1).active {
  transform: rotate(45deg);
}

.ham .st0:nth-child(2).active {
  opacity: 0;
}

.ham .st0:nth-child(3).active {
  transform: rotate(-45deg);
}

.ham .st0 {
  fill: none;
  stroke: var(--base-color1);
  stroke-width: 2.6415;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  transform-origin: 25% 50%;
  transition: all 400ms ease;
}

.mobile-menu-list {
  position: fixed;
  /* top: 20vh; */
  left: -10vw;
  top: -30vh;
  min-width: 50%;
  /* max-width: 82vw; */
  font-family: "Playfair Display";
  font-size: 38px;
  line-height: 2;
  text-align: right;
  z-index: 1002;
  /* float: right; */
  pointer-events: none;
}

@media only screen and (min-width: 800px) {
  .mobile-menu-list {
    min-width: calc(60% + 15px);
  }
}

.mobile-menu-list li {
  transform: translateY(50px);
  transition: transform 3s cubic-bezier(.25, .33, .27, .86), opacity 0.5s ease, padding 400ms ease;
  opacity: 0;
  pointer-events: none;
}

.mobile-menu-list li.active {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.mobile-menu {
  pointer-events: none;
  opacity: 0;
  transition: all 600ms ease-out;
}

.mobile-menu.active {
  pointer-events: auto;
  opacity: 1;
}

.mobile-menu-list li {
  cursor: pointer;
  transition-delay: 0;
  transition-property: transform, opacity;
}

.mobile-menu-list li:hover {
  padding-right: 15px;
  transition-delay: 0;
}

.mobile-menu-list li.active:nth-child(1) {
  transition-delay: 200ms;
  transition-property: transform, opacity;
}

.mobile-menu-list li.active:nth-child(2) {
  transition-delay: 250ms;
  transition-property: transform, opacity;
}

.mobile-menu-list li.active:nth-child(3) {
  transition-delay: 300ms;
  transition-property: transform, opacity;
}

.mobile-menu-list li.active:nth-child(4) {
  transition-delay: 350ms;
  transition-property: transform, opacity;
}

.menu li {
  display: inline-block;
  padding: 0 0 0 30px;
}

.menu {
  position: absolute;
  /* top: -15vh; */
  right: 0;
  /* width: 120px; */
  /* float: center; */
  margin: 0 auto;
  font-size: 15px;
  z-index: 2000;
  font-family: "Harmonia500", sans-serif;
  cursor: pointer;
}

.phone-svg {
  position: absolute;
  top: -10px;
  height: 35px;
}

.phone-svg .st0 {
  opacity: 0.1;
  fill: none;
  stroke: var(--base-color1);
  stroke-width: 3;
  stroke-miterlimit: 10;
  transition: all 200ms ease-out;
}

.phone-svg .st1 {
  fill: var(--base-color1);
  transition: all 200ms ease-out;
}

.phone-svg:hover .st0 {
  opacity: 1;
  stroke: var(--base-color1);
}

/* .phone-svg:hover .st1 {
  fill: var(--base-color1);
} */
.facebook-svg {
  position: absolute;
  top: -10px;
  height: 35px;
}

/*
.facebook-svg:hover .st1 {
  fill: var(--base-color1);
} */
.facebook-svg:hover .st0 {
  opacity: 1;
}

.facebook-svg .st0 {
  opacity: 0.1;
  fill: none;
  stroke: var(--base-color1);
  stroke-width: 3;
  stroke-miterlimit: 10;
  transition: all 200ms ease-out;
}

.facebook-svg .st1 {
  fill: var(--base-color1);
  transition: all 200ms ease-out;
}

/* MENU END */
a {
  color: inherit;
  text-decoration: none;
}

/* FRONT PAGE START */
.logobig .st0 {
  fill: var(--color1);
}

.logobig .st1 {
  fill: var(--color2);
}

.logobig .st2 {
  fill: var(--color3);
}

.logobig {
  width: 40vw;
  max-width: 250px;
}

.logobig2 {
  width: 100px;
  height: 100px;
  overflow: visible !important;
  transition: all 5s ease;
  /* position: absolute;left:0;right:0;margin: 0 auto;top: calc(50% - 50px); */
}

.logobig2:hover {
  /* animation: rotate 8s ease alternate; */
  transform: rotate(360deg);
  transition: all 30s ease;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

.logobig2 .st0 {
  fill: #7D875E;
  transform-origin: center;
  animation: dot 3s cubic-bezier(.6, .26, .12, .85) forwards;
}

.logobig2 .st1 {
  fill: #16403D;
  transform-origin: center;
}

.logobig2 .st1:nth-child(6) {
  animation: in1 2.5s ease forwards;
  transform: scale(100) rotate(180deg);
}

.logobig2 .st1:nth-child(2) {
  animation: in2 2.8s ease forwards;
  transform: translateY(0) translateX(300px) scale(0);
}

.logobig2 .st1:nth-child(3) {
  animation: in3 2.8s ease forwards;
  transform: translateY(0) translateX(-300px) scale(0);
}

.logobig2 .st1:nth-child(4) {
  animation: in4 2.8s ease forwards;
  transform: translateY(-300px) translateX(0) scale(0);
}

.logobig2 .st1:nth-child(5) {
  animation: in5 2.8s ease forwards;
  transform: translateY(300px) translateX(0) scale(0);
}

@media only screen and (max-width: 800px) {
  .logobig2 {
    width: 60px;
    height: 60px
  }
}

@keyframes dot {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

@keyframes in1 {
  from {
    transform: scale(0) rotate(180deg)
  }

  to {
    transform: scale(1) rotate(0)
  }
}

@keyframes in2 {
  from {
    transform: translateY(0) translateX(300px) scale(0);
  }

  to {
    transform: translateY(0) translateX(0) scale(1);
  }
}

@keyframes in3 {
  from {
    transform: translateY(0) translateX(-300px) scale(0);
  }

  to {
    transform: translateY(0) translateX(0) scale(1);
  }
}

@keyframes in4 {
  from {
    transform: translateY(-300px) translateX(0) scale(0);
  }

  to {
    transform: translateY(0) translateX(0) scale(1);
  }
}

@keyframes in5 {
  from {
    transform: translateY(300px) translateX(0) scale(0);
  }

  to {
    transform: translateY(0) translateX(0) scale(1);
  }
}

.fadeUp {
  opacity: 0;
  animation: fadeUp 1s ease forwards;
}

.fade {
  opacity: 0;
  animation: fade 1s ease forwards;
}

.fadeBig {
  opacity: 0;
  animation: fade 2s ease forwards;
}

.delay02 {
  animation-delay: 0.2s;
}

.delay03 {
  animation-delay: 0.3s;
}

.delay04 {
  animation-delay: 0.4s;
}

.delay05 {
  animation-delay: 0.5s;
}

.delay06 {
  animation-delay: 0.6s;
}

.delay07 {
  animation-delay: 0.7s;
}

.delay08 {
  animation-delay: 0.8s;
}

.delay09 {
  animation-delay: 0.9s;
}

.delay1 {
  animation-delay: 1s;
}

.delay11 {
  animation-delay: 1.1s;
}

.delay12 {
  animation-delay: 1.2s;
}

.delay13 {
  animation-delay: 1.3s;
}

@keyframes fadeUp {
  from {
    transform: translateY(50px) translateX(0);
    opacity: 0;
  }

  to {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.location-svg {
  position: relative;
  height: 17px;
  top: 3px;
}

.location-svg .st0 {
  fill: var(--base-color1);
}

.location {
  font-family: "Harmonia500";
  font-size: 15px;
}

.arrow-svg {
  height: 22px;
}

.arrow-svg .st0 {
  fill: none;
  stroke: var(--base-color1);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.programare-online {
  border: 2px rgba(53, 62, 73, 0.1) solid;
  padding: 15px 8px 12px 8px;
  border-radius: 8px;
  font-size: 18px;
  font-family: "Harmonia500";
  width: 200px;
  margin: 0 auto;
  transition: all 200ms ease-out;
  cursor: pointer;
  display: block;
}

.programare-online:hover {
  background: var(--base-color1);
  color: var(--base-color2);
}

.programare-calendar {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100vw;
  border: none;
  height: 100vh;
  display: none;
}

.orar {
  font-size: 15px;
  opacity: 0.3;
  transition: all 200ms ease-out;
  line-height: 1.5;
}

.orar:hover {
  opacity: 1;
}

.orar span {
  padding-left: 20px;
}

/* FRONT PAGE END*/
h1 {
  font-family: "Playfair Display";
  font-weight: 500;
  font-size: 36px;
}

/* SERVICES FRONT START */
.servicii-about {
  font-family: "Harmonia400";
  max-width: 650px;
  float: center;
  margin: 0 auto;
  font-size: 20px;
  line-height: 1.33;
  font-weight: 500;
}

.servicii-about .title {
  font-family: "Playfair Diplay";
  max-width: 750px;
  float: center;
  margin: 0 auto;
  font-size: 42px;
  line-height: 1;
  padding-bottom: 20px;
  /* mix-blend-mode: difference; */
}

.servicii-titlu {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Harmonia500";
}

.underlineSVG {
  position: absolute;
  margin-top: 10px;
  margin-left: -3.5px;
  pointer-events: none;
}

.underlineSVG * {
  fill: var(--base-color1) !important;
}

.servicii-box {
  padding-top: 5vw;
  display: flex;
  /* max-width: 80vw; */
  width: 100%;
}

.servicii-box div {
  width: 33%;
  overflow: hidden;
  padding: 30px 0 30px 0;
  border-radius: 10px;
}

.servicii-box .servicii-icon {
  height: 130px;
  transition: all 1200ms cubic-bezier(0.5, 0.26, 0.42, 0.75);
}

@media only screen and (max-width: 800px) {
  .servicii-box {
    flex-direction: column;
  }

  .servicii-box div {
    width: 100%;
    display: block;
    padding-top: 20%;
  }

  .servicii-box div svg {
    max-height: 130px;
  }
}

.servicii-box div:nth-child(1) svg .st0 {
  fill: var(--color1);
  opacity: 0.08;
}

.servicii-box div:nth-child(1) svg .st1 {
  fill: var(--color1);
}

.servicii-box div:nth-child(2) .st0 {
  opacity: 0.08;
  fill: var(--color2);
}

.servicii-box div:nth-child(2) .st1 {
  fill: var(--color2);
}

.servicii-box div:nth-child(3) .st0 {
  opacity: 0.05;
  fill: var(--color3);
}

.servicii-box div:nth-child(3) .st1 {
  fill: var(--color3);
}

/* ANIM ON HOVER */
.servicii-box div svg {
  overflow: visible;
}

.servicii-box div svg .st0 {
  transition: all 350ms ease;
  transform-origin: center center;
}

.servicii-box div:hover svg .st0 {
  transform: scale(20);
  /* opacity: 0.05; */
  transition: all 1000ms cubic-bezier(0.5, 0.26, 0.42, 0.75);
  /* : 800px; */
}

.servicii-box div:hover .servicii-icon {
  transform: translateY(10px);
}

.servicii-link {
  border-radius: 8px;
  padding: 10px 5px 8px 5px;
  display: inline-block;
  width: 100px;
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
  transition: all 250ms ease-out;
}

.servicii-link-svg {
  width: 20px;
  padding-left: 10px;
  transform: translateY(1px);
}

.servicii-link-svg .arrow-right {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

/* hover animation */
.servicii-link .arrow-right {
  transition: all 400ms ease;
}

.servicii-link:hover .arrow-right {
  transform: translateX(10px);
}

/* button 1 */
.servicii-box div:nth-child(1) .servicii-link {
  color: var(--color1);
  border: 2px var(--color1) solid;
}

.servicii-box div:nth-child(1) .servicii-link-svg .arrow-right {
  stroke: var(--color1);
}

.servicii-box div:nth-child(1) .servicii-link:hover {
  background: var(--color1);
  color: var(--base-color2);
}

.servicii-box div:nth-child(1) .servicii-link:hover .servicii-link-svg .arrow-right {
  stroke: var(--base-color2);
}

/* button 2 */
.servicii-box div:nth-child(2) .servicii-link {
  color: var(--color2);
  border: 2px var(--color2) solid;
}

.servicii-box div:nth-child(2) .servicii-link-svg .arrow-right {
  stroke: var(--color2);
}

.servicii-box div:nth-child(2) .servicii-link:hover {
  background: var(--color2);
  color: var(--base-color2);
}

.servicii-box div:nth-child(2) .servicii-link:hover .servicii-link-svg .arrow-right {
  stroke: var(--base-color2);
}

/* button 3 */
.servicii-box div:nth-child(3) .servicii-link {
  color: var(--color3);
  border: 2px var(--color3) solid;
}

.servicii-box div:nth-child(3) .servicii-link-svg .arrow-right {
  stroke: var(--color3);
}

.servicii-box div:nth-child(3) .servicii-link:hover {
  background: var(--color3);
  color: var(--base-color2);
}

.servicii-box div:nth-child(3) .servicii-link:hover .servicii-link-svg .arrow-right {
  stroke: var(--base-color2);
}

/* DESPRE MINE */
.despre-titlu {
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Harmonia500";
  line-height: 0.8
    /* text-align: center; */
}

.despre-box {
  padding-top: 5vw;
  display: flex;
  /* max-width: 80vw; */
  flex-direction: row;
  width: 100%;
}

.despre-left {
  width: 40%;
  padding: 5%;
}

.despre-right {
  width: 70%;
  padding: 5%;
}

.despre-left h1, .despre-right h1 {
  padding-top: 10px;
  line-height: 0.5;
  font-size: 28px;
}

@media only screen and (max-width: 800px) {
  .despre-box {
    flex-direction: column;
  }

  .despre-left, .despre-right {
    width: 100%;
    padding: 3% 0 20% 0;
  }

  .despre-left .despre-img {
    width: 100%;
    margin: 0 auto;
  }
}

.despre-left .despre-img img {
  width: 100%;
  transition: all 4000ms ease;
  /* transform: scale(1.2); */
}

/*
.despre-box:hover .despre-left .despre-img img {
  transform: scale(1.1);
} */
.despre-left .despre-img {
  overflow: hidden;
  /* border-radius: 8px; */
  width: 100%;
}

.despre-descriere {
  font-size: 15px;
  font-family: 'Harmonia400';
  line-height: 1.33;
}

.despre-minicv {
  border: 2px rgba(22,64,61,0.2) solid;
  border-radius: 8px;
  padding: 10px 18px 7px 42px;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  background: var(--base-color2);
  color: var(--base-color1);
  stroke: var(--base-color1);
  stroke-width: 0.1px;
  fill: var(--color3);
  transition: all 400ms ease;
}

.despre-minicv svg {
  position: absolute;
  height: 20px;
  margin-top: -3px;
  margin-left: -78px;
}

.despre-minicv:hover {
  background: var(--color3);
  color: var(--base-color2);
  stroke: var(--base-color2);
  fill: var(--base-color2);
}

/* BLOG */
.blog-titlu {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Harmonia500";
  text-align: center;
}

.blog-bg {
  position: fixed;
  width: 100vw;
  left: -20vw;
  padding-top: 10vh;
  background: #DBE9E4;
}

@media only screen and (max-width: 800px) {
  .blog-bg {
    position: fixed;
    width: 100vw;
    left: -10vw;
  }
}

.blog-clip-svg {
  overflow: hidden;
  /* height: 30px; */
}

.blog-box {
  display: flex;
  max-width: 60vw;
  margin: 0 auto;
}

.blog-article {
  width: 33%;
}

.blog-clip1 {
  width: 100%;
  /* height: auto; */
  -webkit-clip-path: url(#blog-clip2);
          clip-path: url(#blog-clip2);
}

